import React from 'react';
import Headline from 'headline';
import Icon from 'icon';
import HeroWrapper from 'shared/hero-wrapper';
import Button from 'button';

import s from './page-404.module.scss';

export default function ErrorPage() {
  return (
    <HeroWrapper img="cxn-hero-404" className={s.hero}>
      <div className={s.content}>
        <Icon icon="404" className={s.icon} />
        <Headline dash center h1 white className={s.title}>
          Hey.
        </Headline>
        <p>
          If you want a vacation, visit Miami Beach. Meanwhile, let’s get you
          back on track.
        </p>
        <Button hoverWhite href="/">Back to homepage</Button>
      </div>
    </HeroWrapper>
  );
}
